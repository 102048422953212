import { render, staticRenderFns } from "./Item-horizontal.vue?vue&type=template&id=571c065a&scoped=true&"
import script from "./Item-horizontal.vue?vue&type=script&lang=js&"
export * from "./Item-horizontal.vue?vue&type=script&lang=js&"
import style0 from "./Item-horizontal.vue?vue&type=style&index=0&id=571c065a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "571c065a",
  null
  
)


    import installComponents from "!/home/worker05/actions-runner/_work/rentmagic.website.vue.west-vlaanderen/rentmagic.website.vue.west-vlaanderen/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow})
    

export default component.exports